import React, { useContext, useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, FreeMode } from 'swiper/modules';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import 'swiper/css';
import { DESIGNERS, MAIN, PRINT_TIPS_LIST, REQUESTS, REVIEW } from '../../route/constant';
import ModalContext from '../../context/modal/modal';
import { ls } from '../../constant';
import CategoryMenu from './CategoryMenu';
import Community from './Comuunity';
import Search2 from './Search2';
import { allowScroll, preventScroll } from '../../util/commentImageViewer';
import { Sticky } from 'react-sticky';
import { ImgStyled } from '../../styles/ImgStyled';
import {
  paginationAtom,
  preDesignerListAtom,
  designerListAtom,
  itemDetailNavAtom,
  isWelcomeModal,
  idsAtom,
} from '../../atom/atom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Header } from './Logined2';
import { SUPPLIES_URL } from '../common/constants';
import { useParams } from 'react-router-dom';
import { Ss } from '../../constant';

function Logouted2({
  searchKeyword,
  onChangeSearchKeyword,
  resetSearchKeyword,
  isMobile,
  showMenu = true,
  isPhone,
  position,
}) {
  const isWelcomeModalState = useSetRecoilState(isWelcomeModal);
  const [isOpenCategoryMenu, setIsOpenCategoryMenu] = useState(false);
  const [isOpenCategory, setIsOpenCategory] = useState(true);
  const [isCommunity, setIsCommunity] = useState(false);
  const { state: modalState, action: modalAction } = useContext(ModalContext);
  const [, setPageNation] = useRecoilState(paginationAtom);
  const [gnbMouseHover, setGnbMouseHover] = useState(false);
  const [, setPreDesignerList] = useRecoilState(preDesignerListAtom);
  const [, setDesignerList] = useRecoilState(designerListAtom);

  const [, setsIds] = useRecoilState(idsAtom);

  const itemDetailNav = useRecoilValue(itemDetailNavAtom);
  const scrollY = window.scrollY === 0;
  const { id } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const isLandingPage = location.pathname.includes('/landing');
  const TOP_BANNER_HEIGHT = 45;

  const options = {
    slidesPerView: 'auto',
    // freemode: true,
    centeredSlides: false,
    spaceBetween: 0,
    loop: false,
  };

  const goTo = (e, path) => {
    // 페이지네이션, sort, type filter 값 초기화
    localStorage.removeItem(ls.itemPage);
    localStorage.removeItem(ls.itemSort);
    localStorage.removeItem(ls.itemType);

    e.preventDefault();
    e.stopPropagation();

    // 홈으로 이동 시 이전에 홈에서 unmount될 때 저장된 이전 스크롤 위치는 지운다.
    if (path === MAIN) localStorage.removeItem(ls.prevScrollY);

    navigate(path);
    setIsOpenCategoryMenu(false);
    setIsOpenCategory(true);
    setIsCommunity(false);
    setPageNation(1);

    if (path !== '/my/carts' && path !== '/designers') {
      setDesignerList([]);
      setPreDesignerList(false);
      setsIds('');
    }

    localStorage.setItem('scroll', 0);
    if (document.body.style.position === 'fixed') allowScroll();
  };

  const toggleCategoryMenu = () => {
    setIsCommunity(false);
    setIsOpenCategoryMenu(prev => !prev);
    localStorage.setItem('OpenCategoryMenu', !isOpenCategoryMenu);
  };

  // 카테고리 탭 노출 유무에 따른 scroll 기능을 활성화/비활성화 한다.
  useEffect(() => {
    if (!isOpenCategoryMenu) allowScroll();
    else preventScroll();
  }, [isOpenCategoryMenu]);

  const toggleCategory = () => {
    setIsOpenCategory(prev => !prev);
  };

  const openCommunityTab = () => {
    setIsCommunity(prev => !prev);
  };
  const closeCommunityTab = () => {
    if (isCommunity) setIsCommunity(false);
  };

  const openModal = () => {
    document.body.classList.add('modal-opened');
  };
  const closeModal = () => {
    document.body.classList.remove('modal-opened');
  };

  useEffect(() => {
    localStorage.setItem('OpenCategoryMenu', false);
  }, []);

  useEffect(() => {
    if (modalState.isLogin) {
      setTimeout(() => {
        openModal();
      }, [0]);
    } else {
      closeModal();
      // 모달창이 노출될 때 GNB sticky 라이브러리 CSS가 자동으로 변경되므로 모달창이 닫힌 후에는 스크롤을 탑으로 고정한다.
      if (isPhone) {
        setTimeout(() => {
          if (position === 0) {
            window.scrollTo(0, 10);
            setTimeout(() => {
              // GNB 노출을 위해 다시 맨 위로 이동시킨다.
              window.scrollTo(0, 0);
            }, [0]);
          } else window.scrollTo(0, 0);
        }, [0]);
      }
    }
  }, [modalState.isLogin, modalState.isForgotPassword]);

  useEffect(() => {
    if (modalState.isSignup) {
      setTimeout(() => {
        openModal();
      }, [0]);
    } else {
      closeModal();
      // 모달창이 노출될 때 GNB sticky 라이브러리 CSS가 자동으로 변경되므로 모달창이 닫힌 후에는 스크롤을 탑으로 고정한다.
      if (isPhone) {
        setTimeout(() => {
          if (position === 0) {
            window.scrollTo(0, 10);
            setTimeout(() => {
              // GNB 노출을 위해 다시 맨 위로 이동시킨다.
              window.scrollTo(0, 0);
            }, [0]);
          } else window.scrollTo(0, 0);
        }, [0]);
      }
    }
  }, [modalState.isSignup]);

  useEffect(() => {
    if (modalState.isForgotPassword) {
      setTimeout(() => {
        openModal();
      }, [0]);
    } else {
      closeModal();
      // 모달창이 노출될 때 GNB sticky 라이브러리 CSS가 자동으로 변경되므로 모달창이 닫힌 후에는 스크롤을 탑으로 고정한다.
      if (isPhone) {
        setTimeout(() => {
          if (position === 0) {
            setTimeout(() => {
              // GNB 노출을 위해 다시 맨 위로 이동시킨다.
              window.scrollTo(0, 0);
            }, [0]);
          } else window.scrollTo(0, 0);
        }, [0]);
      }
    }
  }, [modalState.isForgotPassword]);

  useEffect(() => {
    if (modalState.isFacebookNotice) {
      openModal();
    } else {
      closeModal();
      if (modalState.isLogin) {
        openModal();
      }
    }
  }, [modalState.isFacebookNotice, modalState.isLogin]);

  const communityRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (isCommunity && !communityRef?.current?.contains(event.target)) {
        setIsCommunity(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isCommunity]);

  return (
    <Header islanding={isLandingPage} itemDetailNav={itemDetailNav}>
      <Sticky topOffset={!isPhone ? TOP_BANNER_HEIGHT : 0}>
        {({ style }) => (
          <div
            style={style}
            className='sticky-container'
            onMouseEnter={() => {
              setGnbMouseHover(true);
            }}
            onMouseLeave={() => {
              setGnbMouseHover(false);
            }}
          >
            <div className='row1'>
              <Link to='#' className='headerLogo' title='Home' onClick={e => goTo(e, MAIN)}>
                <ImgStyled src='/assets/img/2.0WebImg/ui/fab365.svg' alt='Fab365' />
              </Link>

              <Search2
                searchKeyword={searchKeyword}
                onChangeSearchKeyword={onChangeSearchKeyword}
                resetSearchKeyword={resetSearchKeyword}
              />

              <div className='subMenu'>
                {/* <Link className='gotoSupplies' to={'/apply-designer'} style={{ marginRight: 10 }}>
                  ApplyForm
                </Link> */}
                <Link className='gotoSupplies' to={SUPPLIES_URL} target='_blank' rel='noreferrer'>
                  Supplies
                </Link>
                <button
                  className='gotoSignup'
                  onClick={() => {
                    modalAction.setIsSignup(true);
                    isWelcomeModalState(false);
                    id && localStorage.setItem(Ss.itemNumber, id);
                  }}
                >
                  Sign up
                </button>
                <button
                  className='gotoLogin'
                  onClick={() => {
                    modalAction.setIsLogin(true);
                    isWelcomeModalState(false);
                    id && localStorage.setItem(Ss.itemNumber, id);
                  }}
                >
                  Login
                </button>
              </div>

              {/* 모바일 버전에서 활성화 */}
              {isMobile && (
                <button
                  tabIndex='0'
                  type='button'
                  className='buttonCategory'
                  onClick={toggleCategoryMenu}
                >
                  <div className='iconMenu'></div>
                  <div className='txt1'>Categories</div>
                  <div className='iconArrow'></div>
                </button>
              )}
            </div>

            {/* <div
              className='row2'
              style={{
                transform: pathname.includes('items') ? 'translateY(49px)' : row2Nav,
              }}
            > */}
            <div
              className='row2'
              style={{
                transform:
                  showMenu || gnbMouseHover
                    ? !isMobile
                      ? 'translateY(49px)'
                      : 'translateY(74px)'
                    : 'translateY(0px)',
              }}
            >
              <div className='row2-inner'>
                {/* 데스크탑에서 활성화 */}
                {!isMobile && (
                  <button
                    tabIndex='0'
                    type='button'
                    className='buttonCategory'
                    onClick={toggleCategoryMenu}
                  >
                    <div className='iconMenu'></div>
                    <div className='txt1'>Categories</div>
                    <div className='iconArrow'></div>
                  </button>
                )}

                <nav>
                  <Swiper
                    className='navSwiper'
                    {...options}
                    modules={[Pagination, FreeMode]}
                    freeMode
                  >
                    <div className='swiper-wrapper'>
                      <SwiperSlide className='swiper-slide'>
                        <Link
                          to='#'
                          className={`navMenu ${location.pathname === MAIN && 'active'}`}
                          onClick={e => goTo(e, MAIN)}
                        >
                          <span>HOME</span>
                        </Link>
                      </SwiperSlide>

                      <SwiperSlide className='swiper-slide'>
                        <Link
                          to='#'
                          className={`navMenu ${location.pathname.includes(DESIGNERS) && 'active'}`}
                          onClick={e => goTo(e, DESIGNERS)}
                        >
                          <span>Designers</span>
                        </Link>
                      </SwiperSlide>

                      <SwiperSlide className='swiper-slide'>
                        <Link
                          to='#'
                          className={`navMenu ${
                            location.search.includes('reward_model') && 'active'
                          }`}
                          onClick={e => goTo(e, '/search?tag=reward_model')}
                        >
                          <span>Rewards</span>
                        </Link>
                      </SwiperSlide>

                      <SwiperSlide className='swiper-slide'>
                        <a
                          to='#'
                          className={`navMenu ${
                            (location.pathname.includes(REVIEW) && 'active') ||
                            (location.pathname.includes(REQUESTS) && 'active') ||
                            (location.pathname.includes(PRINT_TIPS_LIST) && 'active') ||
                            (location.pathname.includes('help') && 'active') ||
                            (location.pathname.includes('pages') && 'active')
                          }`}
                          onClick={openCommunityTab}
                        >
                          <span>Community</span>
                        </a>
                      </SwiperSlide>

                      {/* TODO: 1차 개발에서 제외 */}
                      {/* <SwiperSlide className='swiper-slide'>
                  <a href='' className={`navMenu ${location.pathname.includes(HELP) && 'active'}`}>
                    <span>Help</span>
                  </a>
                </SwiperSlide> */}
                    </div>
                  </Swiper>
                </nav>
              </div>
            </div>

            <div className='centerWrap'>
              {isCommunity && (
                <Community
                  goTo={goTo}
                  communityRef={communityRef}
                  style={{
                    transform: showMenu
                      ? !isMobile
                        ? 'translateY(60px)'
                        : 'translateY(93px)'
                      : 'translateY(-600px)',
                  }}
                />
              )}

              <CategoryMenu
                isOpenCategoryMenu={isOpenCategoryMenu}
                isOpenCategory={isOpenCategory}
                toggleCategoryMenu={toggleCategoryMenu}
                toggleCategory={toggleCategory}
                goTo={goTo}
              />
            </div>
          </div>
        )}
      </Sticky>
    </Header>
  );
}

export default Logouted2;
