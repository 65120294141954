export const TAB_PROFILE = 'profile';
export const TAB_ORDER = 'order';
export const TAB_LIKE = 'like';
export const TAB_CREDIT = 'credit';
export const TAB_DOWNLOAD = 'download';
export const TAB_REQUEST = 'request';
export const TAB_REVIEW = 'review';
export const TAB_REWARD_TICKET = 'reward_ticket';
export const TAB_MY_SALES = 'mySales';
export const TAB_COMMENTS = 'comments';
export const TAB_FOLLOWING = 'following';
