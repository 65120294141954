import { Link, useNavigate } from 'react-router-dom';
import { REVIEW } from '../../../route/constant';
import { getMainThumb } from '../../../util/image';
import { getOidUrl } from '../../../util/url';
import { ImgStyled } from '../../../styles/ImgStyled';

const ReviewList = ({ data, page }) => {
  const navigate = useNavigate();

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path, { state: page });
  };

  return (
    <>
      <div className='post-list post-list-4 post-list-2-m'>
        <ul className='list clearfix'>
          {data?.map((r, idx) => {
            const url = getOidUrl(REVIEW, r.id, r.subject, r.body);
            return (
              <div className='list-item' key={idx}>
                <div className='post-list-item post-list-item-square'>
                  <Link to={url} onClick={e => goTo(e, url)} tabIndex='0'>
                    <div className='tb'>
                      <ImgStyled src={getMainThumb(r)} alt='review-img' className='review-img' />
                    </div>
                    <div className='post-desc'>
                      <h3 className='post-title'>{r.subject}</h3>
                      <div className='post-meta'>
                        <span className='author'>
                          By. <span className='name'>{r.user_name}</span>
                        </span>
                        <span className='post-stat'>
                          <span className='hearted'>{r.like_count}</span>
                          <span className='comment'>{r.comment_count}</span>
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            );
          })}
        </ul>
      </div>
    </>
  );
};
export default ReviewList;
