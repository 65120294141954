import Layout from '../../../component/layout/Layout';
import { TAB_PROFILE } from '../component/constant';
import MyTab from '../component/MyTab';
import ProfileEmail from './component/ProfileEmail';
import ProfilePrinterSetting from './component/ProfilePrinterSetting';
import './Profile.css';
import { useEffect } from 'react';
import {
  getUserProfile,
  updateUser,
  updateSubscribe,
  uploadItemImage,
  updatePrinter,
  verifyEmail,
  userDelete,
} from '../../../action/request';
import { useState } from 'react';
import { DEFAULT_AVATAR } from '../../../util/image';
import { useRef } from 'react';
import { useContext } from 'react';
import UserContext from '../../../context/user/user';
import { useNavigate } from 'react-router-dom';
import { ls } from '../../../constant';
import LangContext from '../../../context/lang/lang';
import { loginSignupEn, loginSignupKo } from '../../../context/lang/constant';
import { RETURN_CODE } from '../../../action/constant';
import { useCookies } from 'react-cookie';
const Profile = () => {
  const [printerSettings, setPrinterSettings] = useState([]);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isProfiled, setIsProfiled] = useState(false);
  const [isSubscription, setIsSubscription] = useState(false);
  const [subscription, setSubscription] = useState([]);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [user, setUser] = useState({});
  const [verifyPost, setVerifyPost] = useState(false);
  const [updatePost, setUpatePost] = useState(false);
  const [cookies, setCookie] = useCookies(['autoLogin']);

  const { action: userAction } = useContext(UserContext);
  const { state: langState } = useContext(LangContext);
  const isKorean = langState.lang.includes('ko');
  const loginSignupMessages = isKorean ? loginSignupKo : loginSignupEn;

  const navigate = useNavigate();

  const isLoginUsingToken =
    localStorage.getItem(ls.accessToken) !== 'undefined' &&
    localStorage.getItem(ls.accessToken) !== 'null' &&
    localStorage.getItem(ls.accessToken);

  const selectFile = useRef('');
  const SETTINGS_NOT_READY = 'service.printerProfile.error.settingNotReady';

  useEffect(() => {
    fetchData();
  }, []);

  const userInfoChange = (key, val) => {
    const next = {
      ...user,
      [key]: val,
    };
    setUser(next);
    setIsProfiled(true);
  };

  const fetchData = async () => {
    try {
      const {
        data: { data: res },
      } = await getUserProfile();
      setPrinterSettings(res.printerSlicer);
      setIsEmailVerified(res.isEmailVerified);
      setSubscription(res.subscription.map(item => ({ category: item.category })));
      setUser(res.user);

      // let tagArr = [];
      // uniqueArr.map((row, index) => {
      //   const object = { id: index, tags: row };
      //   tagArr.push(object);
      // });
    } catch (e) {}
  };

  const tryUpdateUser = async () => {
    // 바뀐거 없음
    if (!isProfiled) return null;

    // 현재 비밀번호 없음
    if (!!newPassword.trim().length && !currentPassword.trim().length)
      return alert(loginSignupMessages.LOGIN_ERROR_PASSWORD_NEED);

    // 새비밀번호 없음
    if (!newPassword.trim().length && !!confirmPassword.trim().length)
      return alert(loginSignupMessages.MY_PROFILE_ERROR_NEW_PASSWORD_NEED);
    // 새비밀번호 없음
    if (!!newPassword.trim().length && !confirmPassword.trim().length)
      return alert(loginSignupMessages.MY_PROFILE_ERROR_NEW_PASSWORD_NEED);

    // 새비밀번호와 확인번호가 맞지 않음
    if (newPassword !== confirmPassword)
      return alert(loginSignupMessages.LOGIN_ERROR_PASSWORD_INVALID);

    try {
      const { data } = await updateUser({
        action: 'my_profile',
        params: {
          name: user.name,
          shortDesc: user.short_desc,
          thumbnail: user.thumbnail,
          password: currentPassword,
          newPassword: newPassword,
          newPasswordConfirm: confirmPassword,
        },
      });
      if (data.returnCode === RETURN_CODE.C00000) {
        setCookie('autoLogin', '', { path: '/', maxAge: 0 });
        alert('Saved');
        window.location.href = '/my/profile';
      } else {
        return alert(data.message);
      }
    } catch (e) {
      alert(e);
    }
  };

  const addPrinterSettings = () => {
    if (!isAllSet()) {
      alert('Please select all print options');
      return;
    }
    const next = [].concat(printerSettings).concat([{ slicer_id: '0', printer_id: '0' }]);
    setPrinterSettings(next);
  };

  const changePrinterSettings = (idx, key, val) => {
    const next = [].concat(printerSettings);
    next[idx][key] = val;
    next[idx].isSettingDownloadable = SETTINGS_NOT_READY;
    setPrinterSettings(next);
  };

  const deletePrinter = idx => {
    if (!idx) return alert('You need to have one printer setting');

    const next = [].concat(printerSettings);
    next.splice(idx, 1);
    setPrinterSettings(next);
  };

  const isAllSet = () => {
    return (
      printerSettings.filter(s => {
        return s.slicer_id === '0' || s.printer_id === '0';
      }).length === 0
    );
  };

  const changeSubscription = (category, val) => {
    const next = [].concat(subscription);
    if (val) {
      let idx;
      for (let i = 0; i < next.length; i++) {
        if (category === next[i].category) {
          idx = i;
        }
      }
      next.splice(idx, 1);
    } else {
      next.push({
        category,
      });
    }

    setSubscription(next);
    setIsSubscription(true);
  };

  const tryUpdateSubscribe = async () => {
    setUpatePost(true);
    if (isSubscription === false) {
      alert('There are no changes.');
      setUpatePost(false);
      return;
    }

    var newsletter = '';
    var discount = '';
    var newitems = '';

    for (const items of subscription) {
      if (items.category === '1') {
        newsletter = '1';
      }

      if (items.category === '2') {
        discount = '2';
      }

      if (items.category === '3') {
        newitems = '3';
      }
    }
    try {
      const result = await updateSubscribe({
        newsletter: newsletter,
        discount: discount,
        newitems: newitems,
      });

      if (result.data.returnCode === 'C00000') {
        alert(result.data.data);
      }
      setUpatePost(false);
    } catch (e) {
      alert(e);
      setUpatePost(false);
    }
  };

  const FileChange = e => {
    setIsProfiled(true);
    const file = e.target.files[0];

    try {
      uploadItemImage({ file }).then(result => {
        if (result.data.img) {
          setUser(prevUser =>
            Object.assign({}, prevUser || {}, { thumbnail: result.data.img.url })
          );
        } else {
          alert(result.data.message);
        }
      });
    } catch (e) {
      alert(e);
    }
  };

  const tryUpdatePrinter = async () => {
    const printer = [];
    const slicer = [];

    for (let i = printerSettings.length - 1; i >= 0; i--) {
      printer.push(printerSettings[i].printer_id);
      slicer.push(printerSettings[i].slicer_id);

      if (printer[i] === '0' || slicer[i] === '0') {
        alert('Please select all print options');
        return;
      }
    }

    try {
      await updatePrinter({
        action: 'userPrinter-save-all',
        params: {
          printer: printer.join(','),
          slicer: slicer.join(','),
        },
      });
      alert('Saved');
    } catch (e) {
      alert(e);
    }
  };

  const tryVerifyEmail = async () => {
    setVerifyPost(true);
    try {
      const res = await verifyEmail();
      if (res.data.message === 'OK') {
        alert(
          'We have sent an account verification email to the email address you entered. Please log in after completing authentication. If you can’t find the verification email, please check your spam folder.'
        );
      }
      setVerifyPost(false);
    } catch (e) {
      alert(e);
      setVerifyPost(false);
    }
  };

  const tryUserDelete = async () => {
    if (
      window.confirm(
        'If you close your account, you cannot access the items which you have purchased. Are you sure that you want to close your account?'
      )
    ) {
      try {
        // TODO: 계정삭제 기능 주석
        // await userDelete(localStorage.getItem(ls.refreshToken));
        // userAction.setInit();
        // localStorage.removeItem(ls.accessToken);
        // localStorage.removeItem(ls.refreshToken);
        window.location.href = '/';
      } catch (e) {
        alert(e);
      }
    }
  };

  // 로그인 안한 상태일 때 접근 불가 (프론트 처리)
  useEffect(() => {
    if (!isLoginUsingToken) navigate('/');
  }, []);
  if (!isLoginUsingToken) return null;
  // 초기 값 설정 된 후 접근 가능 (UI 깜빡임 수정)
  if (!user.email || !user.id_user) return null;

  return (
    <>
      <Layout>
        <div id='main' className='profile-page'>
          <div className='page-top mypage'>
            <div className='container'>
              <h1 className='page-title align-left'>My Page</h1>
            </div>
          </div>
          <MyTab tab={TAB_PROFILE} />

          <section className='mypage-section'>
            <div className='container'>
              <div className='mypage-profile'>
                <div className='profile-photo'>
                  <div className='profile-photo-box'>
                    <h3>Profile Photo</h3>
                    <div className='profile-photo-body'>
                      <form action='' method='post' name='profile_photo_form'>
                        <div className='profile-pic'>
                          <img
                            src={user.thumbnail || DEFAULT_AVATAR}
                            className='user-avatar'
                            alt=''
                          />
                        </div>
                        <p className='notice'>JPG, GIF or PNG Recommended Size: 120x120</p>
                        <button
                          type='button'
                          className='btn btn-full loading_color'
                          id='btnFileUpload'
                          onClick={() => selectFile.current.click()}
                        >
                          File Upload
                        </button>
                        <input
                          type='file'
                          accept='image/*'
                          name='user_photo'
                          style={{ display: 'none' }}
                          ref={selectFile}
                          onChange={FileChange}
                        />
                      </form>
                    </div>
                  </div>
                </div>

                <div className='content-box personal-details'>
                  <div className='content-box-header'>
                    <h2>Personal Details</h2>
                  </div>
                  <div className='content-box-body'>
                    {/* 여기 */}
                    <form action='' method='post' name='personal_details_form' className='form'>
                      <input type='hidden' name='thumbnail' value={user.thumbnail} />

                      <fieldset>
                        <div className='form-group'>
                          <label for='userEmail' className='form-label'>
                            E-Mail Address
                          </label>
                          <div className='form-control' style={{ lineHeight: '3rem' }}>
                            {user.email}
                          </div>
                        </div>
                        {!isEmailVerified && (
                          <div className='form-action'>
                            <button
                              type='button'
                              className='btn btn-green btn-fill'
                              data-action='button-emailverify-63ef'
                              onClick={tryVerifyEmail}
                              disabled={verifyPost}
                            >
                              Verify Email
                            </button>
                          </div>
                        )}
                      </fieldset>

                      <fieldset>
                        <div className='form-group'>
                          <label for='userName' className='form-label'>
                            Your Name
                          </label>
                          <div className='form-control'>
                            <input
                              type='text'
                              className='form-input'
                              name='name'
                              id='userName'
                              onChange={e => userInfoChange('name', e.target.value)}
                              value={user.name}
                            />
                          </div>
                        </div>
                        <div className='form-group'>
                          <label for='user_bio' className='form-label'>
                            Your Bio
                          </label>
                          <div className='form-control'>
                            <input
                              type='text'
                              className='form-input'
                              name='short_desc'
                              id='user_bio'
                              onChange={e => userInfoChange('short_desc', e.target.value)}
                              value={user.short_desc}
                            />
                          </div>
                        </div>
                        <hr />
                        <div className='form-group'>
                          <label for='userPassword' className='form-label'>
                            Current Password
                          </label>
                          <div className='form-control'>
                            <input
                              type='password'
                              className='form-input'
                              name='password'
                              id='userPassword'
                              onChange={e => {
                                setCurrentPassword(e.target.value);
                                setIsProfiled(true);
                              }}
                              value={currentPassword}
                            />
                          </div>
                        </div>
                        <div className='form-group'>
                          <label for='userNewPassword' className='form-label'>
                            New Password
                          </label>
                          <div className='form-control'>
                            <input
                              type='password'
                              className='form-input'
                              name='newPassword'
                              id='userNewPassword'
                              onChange={e => {
                                setNewPassword(e.target.value);
                                setIsProfiled(true);
                              }}
                              value={newPassword}
                            />
                          </div>
                        </div>
                        <div className='form-group'>
                          <label for='userConfirmPassword' className='form-label'>
                            Confirm Password
                          </label>
                          <div className='form-control'>
                            <input
                              type='password'
                              className='form-input'
                              name='newPasswordConfirm'
                              id='userConfirmPassword'
                              onChange={e => {
                                setConfirmPassword(e.target.value);
                                setIsProfiled(true);
                              }}
                              value={confirmPassword}
                            />
                          </div>
                        </div>
                      </fieldset>
                      <div className='form-action'>
                        <button
                          type='button'
                          className='btn btn-green btn-fill profile-update'
                          onClick={tryUpdateUser}
                        >
                          Save Changes
                        </button>
                      </div>
                    </form>
                  </div>
                  <ProfileEmail
                    subscription={subscription}
                    onChange={changeSubscription}
                    onClick={tryUpdateSubscribe}
                    disabled={updatePost}
                  />
                  <div className='content-box-header'>
                    <h2>My Printers</h2>
                  </div>

                  <div className='content-box-body'>
                    <form method='post' className='form' id='userdata'>
                      <ProfilePrinterSetting
                        data={printerSettings}
                        onChange={changePrinterSettings}
                        onDelete={deletePrinter}
                      />
                      <div className='form-action'>
                        <button
                          type='button'
                          className='btn btn-blue btn-fill'
                          data-action='add-printer'
                          onClick={addPrinterSettings}
                          style={{ marginRight: '10px' }}
                        >
                          Add New Printer
                        </button>

                        <button
                          type='button'
                          className='btn btn-green btn-fill'
                          data-action='save-printer-profile'
                          onClick={tryUpdatePrinter}
                        >
                          Save Printer
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className='content-box-header' style={{ marginTop: '30px' }}>
                    <h2>
                      <span style={{ color: '#ff6a10' }}>Delete</span> Account
                    </h2>
                  </div>

                  <div className='content-box-body'>
                    <p>
                      If you delete your account, you cannot access the items which you have
                      purchased.
                    </p>
                    <form className='form'>
                      <div className='form-action'>
                        <button
                          type='button'
                          className='btn btn-fill loading_color'
                          data-action='1303-profile-close-account'
                          onClick={tryUserDelete}
                        >
                          Delete
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default Profile;
