import React, { useEffect, useState } from 'react';
import FollowerUserProfile from './FollowerUserProfile';
import styled from 'styled-components';
import { getDesignerFollowers, postDesignerFollowing } from '../../../action/request';
import Pagenation from '../Pagenation';
import { useRecoilValue } from 'recoil';
import { followStatusAtom } from '../../../atom/atom';

const Followrs = ({
  moveScroll,
  selectedSort,
  data,
  followeringPage,
  followerPage,
  setFolloweringPage,
  setFollowerPage,
}) => {
  const studioIdNumber = Number(data);

  const [followerList, setFollowerList] = useState([]);
  const [followingList, setFollowingList] = useState([]);
  const [followerCount, setFollowerCount] = useState(0);
  const [followingCount, setFollowingCount] = useState(1);
  const [, setPageNation] = useState(1);
  const followStatus = useRecoilValue(followStatusAtom);
  const [isLoading, setIsLoading] = useState(true);
  const isLogin = localStorage?.getItem('accessToken');

  const fetchFollowers = async studioId => {
    try {
      const requestBody = {
        page: followerPage,
        sortType: selectedSort?.value,
      };

      const { data: followerData } = await getDesignerFollowers(studioId, requestBody);

      if (followerData?.returnCode === 'C00000') {
        setFollowerList(followerData?.data?.followList);
        setFollowerCount(followerData?.data?.total);
      } else if (followerData?.returnCode === 'C00008' || followerData?.returnCode === 'C00008') {
        setFollowerList([]);
        setFollowerCount(0);
      } else {
        alert(followerData?.message);
      }
    } catch (error) {
      alert(error);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFollowing = async studioId => {
    try {
      const params = {
        page: Number(followeringPage),
        sortType: selectedSort?.value,
      };

      const { data: followingList } = await postDesignerFollowing(studioId, params);

      if (followingList?.returnCode === 'C00000') {
        setFollowingList(followingList?.data?.followingList);
        setFollowingCount(followingList?.data?.total);
      } else if (followingList?.returnCode === 'C00008' || followerList?.returnCode === 'C00008') {
        setFollowingList([]);
        setFollowingCount(0);
      } else {
        alert(followingList?.message);
      }
    } catch (error) {
      alert(error);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchFollowers(studioIdNumber);
  }, [selectedSort, followerPage, followStatus, data]);

  useEffect(() => {
    fetchFollowing(studioIdNumber);
  }, [selectedSort, followeringPage, followStatus, data]);

  return (
    <FollowersStyle>
      <div className='follower'>
        <div className='list'>
          <span className='title'>Following</span>
          <FollowerUserProfile
            followList={followingList}
            gridcolumns={'repeat(auto-fit, minmax(150px, 1fr))'}
          />
        </div>

        {followingCount > 0 && (
          <Pagenation
            count={followingCount}
            setPage={setFolloweringPage}
            setPageNation={setPageNation}
            page={followeringPage}
            moveScroll={moveScroll}
            viewCount={32}
            value={'Followrs'}
            marginTop={'30px'}
          />
        )}
      </div>

      <div className='followings'>
        <div className='list'>
          <span className='title'>Followers</span>
          <FollowerUserProfile
            followList={followerList}
            gridcolumns={'repeat(auto-fit, minmax(150px, 1fr))'}
          />
        </div>

        {followerCount > 0 && (
          <Pagenation
            count={followerCount}
            setPage={setFollowerPage}
            setPageNation={setPageNation}
            page={followerPage}
            moveScroll={moveScroll}
            viewCount={32}
            value={'Followrs'}
            marginTop={'30px'}
          />
        )}
      </div>
    </FollowersStyle>
  );
};

export default Followrs;

const FollowersStyle = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;

  .follower {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 30px 60px;
  }

  .followings {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    position: relative;
    padding: 30px 60px;
  }

  .followings::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 100%;
    background-color: rgb(217, 217, 217);
  }

  .title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 50px;

    display: inline-block;
  }

  .list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media screen and (max-width: 640px) {
    display: grid;
    grid-template-columns: 50% 50%;
    justify-items: center;
    padding: 0px;

    .follower {
      padding: 10px;
    }

    .followings {
      padding: 10px;
    }

    .title {
      font-size: 25px;
      font-weight: 700;
      margin-bottom: 20px;

      display: inline-block;
    }
  }
`;
