import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import Layout from '../../component/layout/Layout';

import { Webpage2StylesContainer } from '../../styles/Webpage2Styles';
import DesignersHeader from '../../component/designers/Header/DesignersHeader';
import DesignersContents from '../../component/designers/Contents/DesignersContents';
import { getDesignerInfoName, getDesignerInfoId } from '../../action/request';
import { DesignersContainer } from '../../component/designers/designers';

import { useRecoilState } from 'recoil';
import {
  followStatusAtom,
  commentTotalCountAtom,
  preDesignerListAtom,
  designerInfoAtom,
} from '../../atom/atom';
import UserContext from '../../context/user/user';

const OnlyDesigners = () => {
  const { state } = useLocation();

  const { state: location } = useLocation();
  const fromDesignerSection = location?.location === 'fromDesignerSection';
  const { designerURL } = useParams();
  const [designerInfo, setDesignerInfo] = useRecoilState(designerInfoAtom);
  const [followValue, setFollowValue] = useState();
  const { state: userData, action: userAction } = useContext(UserContext);
  const isLogin = localStorage?.getItem('accessToken');
  const isDesigner = userData?.isDesigner;
  const [isFollow, setIsFollow] = useRecoilState(followStatusAtom);
  const [, setTotalCount] = useRecoilState(commentTotalCountAtom);
  //list가 유지되는 조건의 atom
  const [, setPreDesignerList] = useRecoilState(preDesignerListAtom);
  const [isApp, setIsApp] = useState(true);
  const navigate = useNavigate();
  const urlLocation = useLocation();

  useEffect(() => {
    if (state?.id) {
      fetchDeignerInfoId();
    } else {
      fetchDeignerInfoName();
    }
    setTotalCount(false);
  }, [designerURL, isLogin]);

  const fetchDeignerInfoName = async () => {
    try {
      const { data: designerData } = await getDesignerInfoName({ isLogin, designerURL });
      if (designerData?.returnCode === 'C00000') {
        setDesignerInfo(designerData.data);
        setIsFollow(designerData.data.my_follow !== 0 ? true : false);
      } else {
        alert(designerData?.message);

        return navigate('/');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDeignerInfoId = async () => {
    try {
      const studioId = state?.id;
      const { data: designerData } = await getDesignerInfoId({ isLogin, studioId });
      if (designerData?.returnCode === 'C00000') {
        setDesignerInfo(designerData.data);
        setIsFollow(designerData.data.my_follow !== 0 ? true : false);
      } else {
        alert(designerData?.message);

        return navigate('/');
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(urlLocation.search);
    const selectedTabIDValue = queryParams.get('selectedTabID');
    if (!selectedTabIDValue) setIsApp(false);
    setPreDesignerList(fromDesignerSection);
  }, []);

  return (
    <Layout>
      <Webpage2StylesContainer>
        <DesignersContainer height={designerInfo ? '100%' : '100vh'}>
          {designerInfo && (
            <>
              {!isApp && (
                <DesignersHeader
                  data={designerInfo}
                  designerURL={designerURL}
                  id={state?.id}
                  setIsFollow={setIsFollow}
                  isFollow={isFollow}
                  followValue={followValue}
                  setFollowValue={setFollowValue}
                  fromDesignerSection={fromDesignerSection}
                />
              )}

              <DesignersContents
                designerURL={designerInfo?.id_studio}
                isDesigner={isDesigner}
                designerId={designerInfo?.id_designer}
                state={state}
              />
            </>
          )}
        </DesignersContainer>
      </Webpage2StylesContainer>
    </Layout>
  );
};

export default OnlyDesigners;
