import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../component/layout/Layout';
import MyTab from '../component/MyTab';
import { TAB_COMMENTS } from '../component/constant';
import { postMyComments } from '../../../action/request';
import { ITEMS } from '../../../route/constant';
import styled from 'styled-components';
import Pagination from '../../Category/component/Pagination';

const MyComments = () => {
  const savedPage = localStorage.getItem('mycomments');
  const tabRef = useRef(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(8);
  const [commentLists, setCommentLists] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (savedPage) {
      setPage(Number(savedPage)); // 문자열을 숫자로 변환
      localStorage.removeItem('mycomments');
    }
  }, []);

  useEffect(() => {
    fetchMyComments();
  }, [page]);

  const fetchMyComments = async () => {
    try {
      const { data: comments } = await postMyComments(page, pageSize);
      if (comments?.returnCode === 'C00000') {
        const commentsData = comments?.data;
        const list = commentsData?.commentList;
        setCommentLists(list);
        setTotal(commentsData?.total);
        setPageSize(commentsData?.size);
      } else {
        if (comments.returnCode !== 'C00009') {
          alert(comments?.message);
        }
      }
    } catch (err) {
      alert(err);
    }
  };

  const goToItemDetailPage = (e, productId) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(ITEMS + '/' + productId, { state: 2 });

    localStorage.setItem('mycomments', page);
  };

  const pageChange = val => {
    setPage(val);

    if (tabRef && tabRef?.current) {
      tabRef.current.style.scrollMarginTop = '150px';
      tabRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  const pageConfirm = val => {
    setPage(val === 0 ? 1 : val);

    if (tabRef && tabRef?.current) {
      tabRef.current.style.scrollMarginTop = '150px';
      tabRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <Layout>
      <div id='main'>
        <div className='page-top mypage'>
          <div className='container'>
            <h1 className='page-title align-left'>My Page</h1>
          </div>
        </div>

        <MyTab tab={TAB_COMMENTS} />
        <MyCommnetsWrap ref={tabRef}>
          <section className='mypage-section'>
            <div className='container'>
              <div className='content-box orders'>
                <div className='content-box-header'>
                  <h2>My Comments</h2>
                </div>
                <div className='content-box-body'>
                  <div className='purchase-product-list'>
                    <ul className='clearfix' style={{ height: '100%' }}>
                      {commentLists?.map((comment, key) => {
                        return (
                          <li key={key}>
                            <div className='purchase-product-list-item'>
                              <div className='comments-item-wrap'>
                                <div
                                  className='purchase-product-list-item-inner'
                                  onClick={e => goToItemDetailPage(e, comment?.id_item)}
                                >
                                  <div className='product-click-pointer'>
                                    <img
                                      src={comment?.thumbnail}
                                      className='tb '
                                      alt=''
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </div>
                                  <div className='product-meta'>
                                    <span className='product-name  product-click'>
                                      {comment?.product_name}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Pagination
            page={page}
            onconfirm={pageConfirm}
            onpagechange={pageChange}
            total={Math.ceil(total / pageSize)}
          />
        </MyCommnetsWrap>
      </div>
    </Layout>
  );
};

export default MyComments;

const MyCommnetsWrap = styled.div`
  padding-bottom: 50px;

  .content-box-body {
    height: 100% !important;
  }

  .product-meta {
    width: 100% !important;
  }
  .product-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media screen and (max-width: 768px) {
    .purchase-product-list-item-inner {
      position: relative;
      align-items: center !important;
    }
  }
`;
