import React, { useEffect, useState, useRef } from 'react';
import Layout from '../../../component/layout/Layout';
import MyTab from '../component/MyTab';
import { TAB_FOLLOWING } from '../component/constant';
import { postMyFollowing } from '../../../action/request';
import FollowerUserProfile from '../../../component/designers/Contents/FollowerUserProfile';
import styled from 'styled-components';
import Pagination from '../../Category/component/Pagination';

const MyFollowing = () => {
  const savedPage = localStorage.getItem('myfollowing');
  const tabRef = useRef(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState();
  const [followList, setFollowList] = useState([]);
  const [pageSize, setPageSize] = useState(31);

  useEffect(() => {
    if (savedPage) {
      setPage(Number(savedPage));
      localStorage.removeItem('myfollowing');
    }
  }, []);

  useEffect(() => {
    fetchMyFollowing();
  }, [page]);

  const fetchMyFollowing = async () => {
    try {
      const params = {
        page: Number(page),
        sortType: 'Latest',
      };

      const { data: followingList } = await postMyFollowing(params);

      if (followingList?.returnCode !== 'C00000') {
        alert(followingList?.meassage);
      } else {
        const list = followingList?.data?.followingList;
        setTotal(followingList?.data?.total);
        setFollowList(list);
      }
    } catch (err) {
      alert(err);
    }
  };

  const pageChange = val => {
    setPage(val);
    if (tabRef && tabRef?.current) {
      tabRef.current.style.scrollMarginTop = '150px';
      setTimeout(() => tabRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }), 300);
    }
  };
  const pageConfirm = val => {
    setPage(val);
    if (tabRef && tabRef?.current) {
      tabRef.current.style.scrollMarginTop = '150px';
      setTimeout(() => tabRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }), 300);
    }
  };

  return (
    <Layout>
      <div id='main'>
        <div className='page-top mypage'>
          <div className='container'>
            <h1 className='page-title align-left'>My Page</h1>
          </div>
        </div>

        <MyTab tab={TAB_FOLLOWING} />

        <FollowingWrap ref={tabRef}>
          <div className='title-wrap'>
            <span>Following</span>
          </div>
          <div className='following-list'>
            <FollowerUserProfile
              followList={followList}
              gridcolumns={'repeat(auto-fill, minmax(150px, max-content))'}
              value={'MyFollowing'}
              page={page}
            />
          </div>
          <Pagination
            page={page}
            onconfirm={pageConfirm}
            onpagechange={pageChange}
            total={Math.ceil(total / pageSize)}
          />
        </FollowingWrap>
      </div>
    </Layout>
  );
};

export default MyFollowing;

const FollowingWrap = styled.div`
  margin: 0 auto;
  max-width: 1340px;
  padding: 0 1.5rem;

  .title-wrap {
    border-bottom: 1px solid #d9d9d9;
    margin-top: 5rem;
    span {
      font-size: 2.375rem;
      padding-bottom: 2.125rem;
      font-weight: 500;
      display: inline-block;
    }
  }

  .following-list {
    padding: 2rem 0;
    height: 100%;
  }
`;
