import React from 'react';
import Pagination from 'react-js-pagination';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

const Pagenation = ({
  count,
  page,
  setPage,
  moveScroll,
  setPageNation,
  value,
  viewCount,
  margin,
  marginTop,
}) => {
  const renderViewDetailsButton = value => {
    if (value === 'left') {
      return <img src={`/assets/img/pagenation/double_arrow_left.png`} className='double' />;
    }
    if (value === 'right') {
      return <img src={`/assets/img/pagenation/double_arrow_right.png`} className='double' />;
    }
    if (value === 'prev') {
      return <img src={`/assets/img/pagenation/navigate_prev.png`} className='navigate' />;
    }
    if (value === 'next') {
      return <img src={`/assets/img/pagenation/navigate_next.png`} className='navigate' />;
    }
  };

  const handlePageChange = page => {
    if (value === 'my') {
      setPage(page);
    } else if (
      value === 'categoryList' ||
      value === 'collectionList' ||
      value === 'setItems' ||
      value === 'searchList'
    ) {
      setPage(page);
      setPageNation && setPageNation(page);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      setPage(page);
      setPageNation && setPageNation(page);
      if (value === 'comment' || value === 'review') {
        moveScroll(value === 'comment' ? 2 : 1);
      } else {
        moveScroll();
      }
    }
  };

  return (
    <PaginationBox margin={margin} isMobile={isMobile} marginTop={marginTop}>
      <Pagination
        activePage={page}
        itemsCountPerPage={viewCount}
        totalItemsCount={Number(count)}
        pageRangeDisplayed={isMobile ? 3 : 10}
        onChange={handlePageChange}
        firstPageText={renderViewDetailsButton('left')}
        lastPageText={renderViewDetailsButton('right')}
        prevPageText={renderViewDetailsButton('prev')}
        nextPageText={renderViewDetailsButton('next')}
      />
    </PaginationBox>
  );
};

export default Pagenation;
const PaginationBox = styled.div`
  margin: ${props => (props.margin ? props.margin : '100px 0px')};

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
  ul {
    list-style: none;
    padding: 0;
    margin-bottom: 0px !important;
  }
  ul.pagination li {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-color: #efefef;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Outfit';
    margin-right: 5px;
    border-radius: 4px;
    margin-bottom: 0px !important;
  }
  ul.pagination li:first-child {
    border-radius: 5px 0 0 5px;
  }
  ul.pagination li:last-child {
    border-radius: 0 5px 5px 0;
  }
  ul.pagination li a {
    text-decoration: none;
    color: #747474;
    font-size: 1rem;
    font-weight: 500;
  }
  ul.pagination li.active a {
    color: white;
    font-weight: 500;
  }
  ul.pagination li.active {
    background-color: #242424;
  }
  ul.pagination li a:hover,
  ul.pagination li a.active {
    color: #fff;
  }

  .navigate {
    width: 6px;
  }

  .double {
    width: 12px;
  }

  @media screen and (max-width: 450px) {
    /* margin-top: ${props => (props.isMobile ? '60px' : '30px')}; */

    margin-top: ${props =>
      props.isMobile ? (props.marginTop ? props.marginTop : '60px') : '30px'};
  }
`;
