import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { getNotiInfo, postNotiInfoUpdate } from '../../../action/request';

const NOTIFICATION_TYPES = ['follow', 'sale', 'like', 'review', 'comment'];

const NotificationToggle = ({ setSelectedTab }) => {
  const userAgent = navigator.userAgent.toLowerCase();
  const navigate = useNavigate();

  const [subscription, setSubscription] = useState(
    NOTIFICATION_TYPES.reduce((acc, type) => ({ ...acc, [type]: 'off' }), {})
  );

  const fetchNotiInfo = useCallback(async () => {
    try {
      const { data: noti } = await getNotiInfo();
      if (!noti || noti.returnCode !== 'C00000') {
        setTimeout(() => setSelectedTab(0), 200);

        throw new Error(noti?.message);
      }
      setSubscription(noti.data);
    } catch (error) {
      console.error('Failed to fetch Noti Info:', error);
      alert(`${error.message}`);
      setSelectedTab(0);
    }
  }, []);

  useEffect(() => {
    if (!userAgent?.includes('motilink')) {
      alert('This page is exclusive to the app. Redirecting to the home page.');
      return navigate('/');
    }
    fetchNotiInfo();
  }, [fetchNotiInfo]);

  const handleChange = (name, isChecked) => {
    setSubscription(prev => ({
      ...prev,
      [name]: isChecked ? 'on' : 'off',
    }));
  };

  const handleClick = useCallback(async () => {
    try {
      const { data: noti } = await postNotiInfoUpdate(subscription);
      alert(noti?.message);
    } catch (error) {
      console.error('알림 정보 업데이트 실패:', error);
      alert(
        `An error occurred while updating the notification information.\nError: ${error.message}`
      );
    }
  }, [subscription]);

  return (
    <Notification>
      {userAgent?.includes('motilink') && (
        <div className='content-box-body'>
          <form className='form' name='subscribe'>
            {NOTIFICATION_TYPES?.map(type => (
              <div key={type} className='noti-wrap'>
                <label className='form-label'>{type.charAt(0).toUpperCase() + type.slice(1)}</label>
                <div>
                  <label className='input-checkbox'>
                    <input
                      type='checkbox'
                      name={type}
                      onChange={e => handleChange(type, e.target.checked)}
                      checked={subscription[type] === 'on'}
                    />
                    <span className='label'></span>
                  </label>
                </div>
              </div>
            ))}
            <div className='noti-btn'>
              <button
                type='button'
                className='btn btn-green btn-fill'
                data-action='subscribe'
                onClick={handleClick}
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      )}
    </Notification>
  );
};

export default NotificationToggle;

const Notification = styled.div`
  margin: auto;
  padding: 30px 30px 100px 30px;
  max-width: 50%;

  .noti-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    margin-bottom: 10px;
    label {
      width: 20%;
    }
  }

  .form-action {
    .btn.btn-green.btn-fill {
      padding: 0px 10px;
    }
  }

  .noti-btn {
    display: flex;
    justify-content: flex-end;
    button {
      margin-top: 50px;
      padding: 0px 10px;
    }
  }

  @media screen and (max-width: 768px) {
    margin: auto;
    padding: 30px 30px 100px 30px;
    max-width: 100%;

    .form-group.form-group-inline .form-label + .form-control {
      margin-left: 21.375rem !important;
    }

    .noti-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      margin-bottom: 10px;
      label {
        width: 50%;
      }
    }
  }
`;
