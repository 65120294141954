import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { removeSpecialCharactersAndSpaces } from '../../section/util';
import { DESIGNERS } from '../../../route/constant';
import { selectedTabAtom, designerInfoAtom, selectedSortAtom } from '../../../atom/atom';
import { useRecoilState } from 'recoil';

const FollowerUserProfile = ({ followList, gridcolumns, value, page }) => {
  const navigate = useNavigate();
  const [, setSeletedSort] = useRecoilState(selectedSortAtom);
  const [, setSelectedTab] = useRecoilState(selectedTabAtom);

  const [designerInfo, setDesignerInfo] = useRecoilState(designerInfoAtom);

  const onClickGoto = (e, data) => {
    e.stopPropagation();
    if (value !== 'MyFollowing') {
      if (!data?.studio_name || Number(data?.id_studio) === Number(designerInfo?.id_studio)) {
        return;
      }
    }

    if (value === 'MyFollowing') {
      localStorage.setItem('myfollowing', page);
    }

    if (data?.studio_name) {
      setDesignerInfo(false);

      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 500);
      setSeletedSort({ title: 'Latest', value: 'Latest' });
      setSelectedTab(0);
      navigate(`${DESIGNERS}/${removeSpecialCharactersAndSpaces(data?.studio_name)}`);
    }
  };

  return (
    <>
      <FollowerUerProfileStyle
        className='FollowerUerProfileStyle'
        followList={followList.length < 8}
        gridcolumns={gridcolumns}
      >
        {followList &&
          followList.map((followListData, index) => {
            return (
              <article key={index} onClick={e => onClickGoto(e, followListData)}>
                <img
                  src={
                    followListData.thumbnail === ''
                      ? '/assets/img/designers/follower_user_profile.png'
                      : followListData?.thumbnail || followListData?.logo
                  }
                  alt=''
                />
                <h3>{followListData.name}</h3>
              </article>
            );
          })}
      </FollowerUerProfileStyle>
    </>
  );
};

export default FollowerUserProfile;

const FollowerUerProfileStyle = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px 10px;
  justify-items: center;
  padding-bottom: ${props => (props.followList ? '150px' : '50px')};

  article {
    height: 198px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 15px;
    box-sizing: border-box;
    img {
      width: 120px;
      aspect-ratio: 1 / 1;
      border-radius: 50%;
      margin-bottom: 15px;
      object-fit: fill;
    }

    h3 {
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      font-size: 14px;
      white-space: normal;
      word-wrap: break-word;
      text-align: center;
      color: #232323;
      max-width: 120px;
    }
  }

  @media screen and (max-width: 450px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    padding-bottom: 30px;

    article {
      display: flex;
      align-items: center;
      width: 100%;
      height: 60px;
      max-width: 360px;
      padding: 6px;

      img {
        width: 48px;
        margin-bottom: 0px;
      }

      h3 {
        margin-left: 16px;
        white-space: normal;
        word-wrap: break-word;
        flex-grow: 1;
        overflow-wrap: break-word;
        word-break: break-all;
        text-align: left;
      }
    }
  }
`;
